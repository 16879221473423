body {
	position: relative;
	min-height: 100vh;
	padding-bottom: 132px;
	font-family: 'Source Sans Pro', sans-serif;
}

@media screen and (max-width: 992px) {
	body {
		padding-bottom: 206px;
	}
}

.dark-cell {
	background-color: rgba(0,0,0,.05);
}

.view {
	margin: auto;
	width: 100%;
}

.wrapper {
	position: relative;
	overflow: auto;
	border: 1px solid #dee2e6;
	white-space: nowrap;
}

.sticky-col {
	position: -webkit-sticky;
	position: sticky;
	background-color: #eaecf1;
}

.first-col {
	left: 0px;
}

.ql-editor {
	min-height: 200px;
}

.html-outer > p {
	margin-bottom: 0%;
}

img {
	width: 100%;
}

#footer {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1rem;
	background: #000000;
	padding: 38px 0 36px;
	text-align: left;
	color: white;
	font: 'Lato';
}

#footer a:link, #footer a:visited, #footer a:active, .footer {
	color: #ffffff;
	font-size: 14px;
}

#footer a:hover {
	color: #1951B4;
}